/**
 * Компонент `About` для отображения информации о клинике.
 *
 * Этот компонент включает в себя заголовок, изображение, подпись, описание и список преимуществ клиники.
 * Использует изображения из ассетов для отображения портрета и знаков.
 *
 * @component
*/

import imageSergey from '../../assets/img/Sergey-full.jpg';
import signMobile from '../../assets/img/sign_ten_mob.png';
import signTablet from '../../assets/img/sign_ten.png';
import Sign from '../elements/Sign';

export default function About() {
  return (
    <section className="section-about">
      <div className="container">
        <div className="section-about__content">
          <h2 className="section-about__title heading-secondary">
            О клинике Доктор Тиз
          </h2>

          <div className="section-about__img-box">
            <img
              src={imageSergey}
              alt="Портрет Сергея Хончеловича."
              className="section-about__img"
            />
          </div>

          <Sign type="mobile" img={signMobile} />

          <div className="section-about__desc">
            <p className="text">
              Прежде чем решиться на открытие своей клиники я работал во многих других стоматологиях. Это помогло мне в деталях понять какими должны быть пространство и сервис, чтобы дать клиентам лучшее.
            </p>

            <p className="text">
              Клиника «Доктор Тиз» — это место, где лечат с комфортом.
            </p>
          </div>

          <ul className="section-about__features" aria-label="Преимущества">
            <li className="section-about__feature">Не завышаем цены</li>
            <li className="section-about__feature">Любим свою работу</li>
            <li className="section-about__feature">За естественную улыбку</li>
            <li className="section-about__feature">Лечим без боли</li>

            <Sign type="tablet" img={signTablet} />
          </ul>
        </div>
      </div>
    </section>
  );
}