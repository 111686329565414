/**
 * Компонент `Sign` для отображения подписи директора.
 *
 * @component
 * @param {Object} props - Объект свойств компонента.
 * @param {string} props.type - Тип, влияющий на классы CSS.
 * @param {string} props.img - Изображение подписи.
 */

export default function Sign({ type, img}) {
  return (
    <div className={`section-about__sign section-about__sign--${type} text`}>
      <div className="section-about__sign-text">
        <span>С.Х. Тен</span>, директор «Доктор Тиз»
      </div>

      <img
        src={img}
        alt="Подпись доктора Тена С.Х."
        className="section-about__sign-img"
        aria-hidden="true"
      />
    </div>
  );
}