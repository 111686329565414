import { useState, useEffect, useContext } from 'react';
import { DoctorsContext } from '../../App';
import Vacancy from '../elements/Vacancy';
import { vacanciesApi } from '../../api/vacancies';

export default function Vacancies() {
  const { baseUrl } = useContext(DoctorsContext);
  const [vacancies, setVacancies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVacancies = async () => {
      try {
        const data = await vacanciesApi.fetchVacancies();
        setVacancies(data);
      } catch (error) {
        console.error('Ошибка при загрузке вакансий:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchVacancies();
  }, []);

  if (loading) return null;
  if (error) return null;
  if (!vacancies.length) return null;

  return (
    <div className="vacancies">
      <div className="container">
        <h2 className="vacancies__heading" id="vacancies">Вакансии «Доктор Тиз»</h2>
      </div>
ы
      <ul className="vacancies__list" aria-labelledby="vacancies">
        {vacancies.map(v => (
          <li className="vacancies__item vacancy" key={v.id}>
            <Vacancy vacancy={v} />            
          </li>
        ))}
      </ul>
    </div>
  );
} 